<template>
  <Modal
    footer-hide
    fullscreen
    :title="farm ? `${farm.name}地块管理` : '地块管理'"
    :value="value"
    @input="(val) => $emit('input', val)"
  >
    <div class="plant-area">
      <x-table
        :no-data-text="CA('plantArea_check') ? '暂无数据' : '暂无数据查询权限'"
        :columns="table.columns"
        :data="table.data"
        :loading="table.loading"
        :config="config"
        @add="add"
        @search="search"
        @page-change="pageChange"
      ></x-table>

      <Modal
        v-model="modal.show"
        :title="modal.title"
        @on-visible-change="modalShow"
      >
        <Form ref="form" :model="form" :rules="rules" :label-width="110">
          <FormItem label="地块编号" prop="name">
            <Input clearable v-model="form.name"></Input>
          </FormItem>
          <!-- <FormItem label="所属种植基地" prop="farm">
            <Select v-model="form.farm">
              <Option
                v-for="(item, index) in farmList"
                :key="index"
                :value="item.id + '/' + item.siteType"
                >{{ item.name }}</Option
              >
            </Select>
          </FormItem> -->
          <FormItem label="地块面积" prop="area">
            <Input v-model="form.area">
              <span slot="append">㎡</span>
            </Input>
          </FormItem>
          <FormItem label="地力等级" prop="areaLevel">
            <Select v-model="form.areaLevel">
              <Option
                v-for="item in areaGrade"
                :value="item.name"
                :key="item.id"
                >{{ item.name }}</Option
              >
            </Select>
          </FormItem>
          <FormItem label="地块绘制" prop="mapPosition">
            <Input
              :placeholder="
                form.farm ? '点击右侧绘制地块地图' : '请先选择种植基地'
              "
              v-model="form.mapPosition"
              disabled
            >
              <span
                slot="append"
                class="iconfont"
                :style="{ cursor: form.farm ? 'pointer' : 'not-allowed' }"
                @click="drawMap"
                >&#xe648;</span
              >
            </Input>
          </FormItem>
          <FormItem label="备注">
            <Input
              type="textarea"
              :rows="3"
              clearable
              v-model="form.remark"
            ></Input>
          </FormItem>
        </Form>
        <p slot="footer" style="text-align: center">
          <Button type="primary" :loading="modal.submitLoading" @click="submit"
            >提交</Button
          >
          <Button @click="() => (modal.show = false)">取消</Button>
        </p>
      </Modal>
      <draw-map-modal
        v-model="drawMapModalShow"
        @draw-finish="drawFinish"
        :backgroundPosition="backgroundPosition"
        :mapPosition="form.mapPosition"
      ></draw-map-modal>
    </div>
  </Modal>
</template>

<script>
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
export default {
  props: {
    value: Boolean,
    farm: Object,
  },
  components: {
    drawMapModal,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "地块编号",
            minWidth: 110,
            key: "name",
          },
          {
            title: "所属种植基地",
            minWidth: 110,
            key: "farmName",
          },
          {
            title: "地块面积",
            minWidth: 110,
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "地力等级",
            minWidth: 100,
            key: "areaLevel",
          },
          {
            title: "备注",
            minWidth: 100,
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("plantArea_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("plantArea_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        // farm: "",
        areaLevel: "",
      },
      rules: {
        name: [{ required: true, message: "请填写地块编号" }],
        area: [
          { required: true, message: "请填写地块面积" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的面积",
          },
        ],
        areaLevel: [{ required: true, message: "请选择地力等级" }],
        // farm: [{ required: true, message: "请选择种植基地" }],
        mapPosition: [{ required: false, message: "请勾画地块" }],
      },
      farmList: [],
      drawMapModalShow: false,
      backgroundPosition: [],
      search_data: {},
      areaGrade: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增地块",
            ca: "plantArea_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "地块编号",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  watch: {
    value(value) {
      if (value) this.getList();
      else {
        this.table.data = [];
        this.table.loading = false;
      }
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增地块",
        submitLoading: false,
      };
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    getFarm() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        siteType: "1",
      }).then((res) => {
        this.farmList = res.list;
      });
    },
    drawMap() {
      if (!this.farm) return;
      // let farm = this.farmList.filter(
      //   (item) => item.id == this.form.farm.split("/")[0]
      // )[0];
      let backgroundPosition = [];
      let mapPosition = JSON.parse(this.farm.mapPosition);
      Array.isArray(mapPosition) && backgroundPosition.push(...mapPosition);
      this.table.data.forEach((item) => {
        if (this.form.id && item.id == this.form.id) return;
        let temp = JSON.parse(item.mapPosition);
        Array.isArray(temp) && backgroundPosition.push(...temp);
      });
      this.backgroundPosition = backgroundPosition;
      // if (!this.form.mapPosition) {
      //   let farm = this.farmList.filter(
      //     (item) => item.id == this.form.farm.split("/")[0]
      //   )[0];
      //   let mapPosition = JSON.parse(farm.mapPosition);
      //   mapPosition.features[0].properties.editArea = false;
      //   farm.mapPosition = mapPosition;
      //   if (this.table.data.length > 0) {
      //     let scene = this.table.data
      //       .filter((el) => el.farmId == this.form.farm.split("/")[0])
      //       .map((item) => JSON.parse(item.mapPosition));
      //     let features = scene.map((item) => {
      //       item.features.forEach((el) => {
      //         el.properties.editArea = false;
      //       });
      //       return item;
      //     });
      //     let m = [];
      //     features.forEach((item) => {
      //       m = m.concat(item.features);
      //     });
      //     let n = farm.mapPosition;
      //     n.features.push(...m);
      //     farm.mapPosition = n;
      //   }
      //   this.form.mapPosition = JSON.stringify(farm.mapPosition);
      // }
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data);
      this.form.thirdLongLat = data[0].center;
      this.drawMapModalShow = false;
    },

    getList() {
      if (!this.CA("plantArea_check")) return;
      if (!this.farm) return;
      this.table.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "1",
        farmId: this.farm.id,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    getAreaGrade() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "214",
      }).then((res) => {
        this.areaGrade = res.list;
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      // this.form.farm = row.farmId + "/" + row.siteType;
      // let mapPosition = JSON.parse(
      //   this.farmList.filter((item) => item.id == row.farmId)[0].mapPosition
      // );
      // mapPosition.features[0].properties.editArea = false;
      // let data = this.table.data.filter(
      //   (item) => item.id != row.id && item.farmId == row.farmId
      // );
      // if (data.length > 0) {
      //   let scene = data.map((item) => JSON.parse(item.mapPosition));
      //   let features = scene.map((item) => {
      //     item.features.forEach((el) => {
      //       el.properties.editArea = false;
      //     });
      //     return item;
      //   });
      //   let m = [];
      //   features.forEach((item) => {
      //     m = m.concat(item.features);
      //   });
      //   mapPosition.features.push(...m);
      // }
      // let features = JSON.parse(row.mapPosition).features.map((item) => {
      //   item.properties.editArea = true;
      //   return item;
      // });
      // mapPosition.features.push(...features);
      // this.form.mapPosition = JSON.stringify(mapPosition);
      this.modal = {
        show: true,
        title: "编辑地块",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.PRODUCTION_AREA.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        farm: "",
        areaLevel: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      if (!this.farm) return;
      // let n = JSON.parse(this.form.mapPosition);
      // let features = n.features.filter(
      //   (item) => item.properties.editArea != false
      // );
      // if (features.length == 0) {
      //   this.form.mapPosition = "";
      // } else {
      //   n.features = features;
      //   this.form.mapPosition = JSON.stringify(n);
      // }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.farm.id;
        params.siteType = this.farm.siteType;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.PRODUCTION_AREA.UPDATE
            : this.$api.PRODUCTION_AREA.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    // this.getFarm();
    this.getAreaGrade();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
/deep/ td.ivu-table-expanded-cell {
  background: #efefef;
}
</style>
